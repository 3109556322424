import axios from 'axios';

// Create a handler that can be set from React components
let unauthorizedHandler = () => {
  window.location.href = '/login';
};

// Add a function to set the handler
export const setUnauthorizedHandler = (handler) => {
  unauthorizedHandler = handler;
};

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://test.rocksoliddata.solutions';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// Request interceptor for API calls
api.interceptors.request.use(
  async config => {
    // Get CSRF token only for non-GET requests
    if (config.method !== 'get') {
      const response = await axios.get(`${API_BASE_URL}/api/auth/csrf/`, {
        withCredentials: true
      });
      config.headers['X-CSRFToken'] = response.data.csrfToken;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401) {
      // Use the handler instead of direct navigation
      unauthorizedHandler();
    }
    return Promise.reject(error);
  }
);

const cache = new Map();

export const getCached = async (key, fetchFn, ttl = 5 * 60 * 1000) => {
  const cached = cache.get(key);
  if (cached && cached.timestamp > Date.now() - ttl) {
    return cached.data;
  }
  
  const data = await fetchFn();
  cache.set(key, { data, timestamp: Date.now() });
  return data;
};

export default api;