import React, { useRef, useState, useEffect } from 'react';
import loadGoogleMapsScript from '../utils/loadGoogleMapsScript';

const AddressAutocomplete = ({ onSelect, defaultValue, className }) => {
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [status, setStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const initializeAutocomplete = async () => {
            try {
                const result = await loadGoogleMapsScript();
                if (result.success) {
                    setStatus('enabled');
                } else {
                    setStatus('disabled');
                    setErrorMessage('Address autocomplete is currently unavailable');
                }
            } catch (error) {
                setStatus('disabled');
                setErrorMessage('Failed to load address autocomplete');
            }
        };

        initializeAutocomplete();
    }, []);

    useEffect(() => {
        if (status !== 'enabled') return;

        try {
            autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
                componentRestrictions: { country: 'us' },
                fields: ['address_components', 'formatted_address'],
                types: ['address']
            });

            const listener = autocompleteRef.current.addListener('place_changed', () => {
                const place = autocompleteRef.current.getPlace();
                if (!place.address_components) return;

                const address = {
                    street: '',
                    city: '',
                    state: null,
                    zip_code: ''
                };

                place.address_components.forEach(component => {
                    const type = component.types[0];
                    if (type === 'street_number') {
                        address.street = component.long_name;
                    } else if (type === 'route') {
                        address.street += (address.street ? ' ' : '') + component.long_name;
                    } else if (type === 'locality') {
                        address.city = component.long_name;
                    } else if (type === 'administrative_area_level_1') {
                        address.state = { value: component.short_name, label: component.long_name };
                    } else if (type === 'postal_code') {
                        address.zip_code = component.long_name;
                    }
                });

                onSelect(address);
            });

            return () => {
                if (listener && window.google) {
                    window.google.maps.event.removeListener(listener);
                }
            };
        } catch (error) {
            console.warn('Error initializing Google Places Autocomplete:', error);
            setStatus('disabled');
            setErrorMessage('Error initializing address autocomplete');
        }
    }, [status, onSelect]);

    return (
        <div className="relative">
            <input
                ref={inputRef}
                type="text"
                defaultValue={defaultValue}
                className={`${className} ${status === 'disabled' ? 'bg-gray-100' : ''}`}
                placeholder={status === 'enabled' ? "Start typing an address..." : "Manual address entry only"}
                disabled={status === 'disabled'}
            />
            {status === 'disabled' && errorMessage && (
                <p className="text-sm text-gray-500 mt-1">{errorMessage}</p>
            )}
        </div>
    );
};

export default AddressAutocomplete;