import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    is_admin: false,
    is_active: true
  });
  const [editingUser, setEditingUser] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/auth/users/');
      setUsers(response.data);
    } catch (error) {
      setError('Error fetching users');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updateFunc = editingUser ? setEditingUser : setNewUser;
    updateFunc(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleEdit = (user) => {
    setEditingUser({
      id: user.id,
      username: user.username,
      email: user.email,
      password: '', // Clear password field for editing
      is_admin: user.is_superuser,
      is_active: user.is_active
    });
    setError('');
    setSuccess('');
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (editingUser) {
        await api.put(`/api/auth/users/${editingUser.id}/`, editingUser);
        setSuccess('User updated successfully');
        setEditingUser(null);
      } else {
        await api.post('/api/auth/users/', newUser);
        setSuccess('User created successfully');
        setNewUser({
          username: '',
          email: '',
          password: '',
          is_admin: false,
          is_active: true
        });
      }
      fetchUsers();
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      setError(Array.isArray(errorMessage) ? errorMessage.join(' ') : errorMessage || `Error ${editingUser ? 'updating' : 'creating'} user`);
    }
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#006F3E]"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex flex-col p-6 bg-gray-50 border-b">
        <div className="flex justify-between items-center mb-6">
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            onClick={handleBackToDashboard}
          >
            ← Back to Dashboard
          </button>
          <h2 className="text-3xl font-bold text-[#006F3E] mb-0 px-4 py-2 bg-white rounded-lg shadow-sm">
            User Management
          </h2>
          <div className="w-[104px]"></div> {/* Spacer for alignment */}
        </div>
      </div>

      {/* Create/Edit User Form */}
      <div className="p-6 bg-gray-50 border-b">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold text-[#006F3E] mb-6">
            {editingUser ? 'Edit User' : 'Create New User'}
          </h3>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="bg-green-50 border-l-4 border-[#006F3E] p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-[#006F3E]" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-[#006F3E]">{success}</p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                  Username
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name="username"
                    value={editingUser ? editingUser.username : newUser.username}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 pl-10 
                             border border-[#006F3E] bg-white rounded-md shadow-sm 
                             focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] 
                             sm:text-sm"
                    required
                  />
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </span>
                </div>
                <p className="text-xs text-gray-500">Required field</p>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 flex items-center">
                  Email
                  <span className="ml-2 text-xs text-gray-500 bg-gray-100 px-2 py-0.5 rounded">Optional</span>
                </label>
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    value={editingUser ? editingUser.email : newUser.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 pl-10 
                             border border-[#006F3E] bg-white rounded-md shadow-sm 
                             focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] 
                             sm:text-sm"
                  />
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <p className="text-xs text-gray-500">We'll never share your email with anyone else</p>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                  Password {editingUser && <span className="text-gray-500 text-xs">(Leave blank to keep current)</span>}
                </label>
                <div className="relative">
                  <input
                    type="password"
                    name="password"
                    value={editingUser ? editingUser.password : newUser.password}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 pl-10 
                             border border-[#006F3E] bg-white rounded-md shadow-sm 
                             focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] 
                             sm:text-sm"
                    required={!editingUser}
                  />
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>
                <p className="text-xs text-gray-500">Must be at least 8 characters</p>
              </div>

              <div className="space-y-4 flex flex-col justify-center">
                <div className="flex items-center p-4 bg-gray-50 rounded-lg space-x-3 hover:bg-gray-100 transition-colors duration-200">
                  <input
                    type="checkbox"
                    name="is_admin"
                    checked={editingUser ? editingUser.is_admin : newUser.is_admin}
                    onChange={handleInputChange}
                    className="h-4 w-4 rounded border-[#006F3E] text-[#006F3E] 
                             focus:ring-[#006F3E] focus:ring-offset-0"
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Admin User
                    </label>
                    <p className="text-xs text-gray-500">Can manage other users and settings</p>
                  </div>
                </div>

                <div className="flex items-center p-4 bg-gray-50 rounded-lg space-x-3 hover:bg-gray-100 transition-colors duration-200">
                  <input
                    type="checkbox"
                    name="is_active"
                    checked={editingUser ? editingUser.is_active : newUser.is_active}
                    onChange={handleInputChange}
                    className="h-4 w-4 rounded border-[#006F3E] text-[#006F3E] 
                             focus:ring-[#006F3E] focus:ring-offset-0"
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Active User
                    </label>
                    <p className="text-xs text-gray-500">User can log in and access the system</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-4 pt-6">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-6 py-2.5 
                         bg-[#006F3E] hover:bg-[#005a32] text-white font-bold 
                         rounded-md transition duration-300 ease-in-out
                         shadow-sm space-x-2 min-w-[140px]"
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d={editingUser 
                          ? "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          : "M12 6v6m0 0v6m0-6h6m-6 0H6"} />
                </svg>
                <span>{editingUser ? 'Update User' : 'Create User'}</span>
              </button>
              
              {editingUser && (
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="inline-flex items-center justify-center px-6 py-2.5 
                           bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold 
                           rounded-md transition duration-300 ease-in-out
                           shadow-sm space-x-2 min-w-[140px]"
                >
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <span>Cancel</span>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* User List */}
      <div className="p-6">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-[#e6f3ee] border-b border-[#b3d9c8]">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Username</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Email</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Role</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Status</th>
                  <th className="px-6 py-4 text-center text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.username}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.is_superuser ? 'Admin' : 'Staff'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.is_active 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {user.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <button
                        onClick={() => handleEdit(user)}
                        className="bg-[#006F3E] hover:bg-[#005a32] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {users.length === 0 && (
            <div className="text-center py-4 text-base text-gray-500">No users found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
