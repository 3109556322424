import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const Dashboard = () => {
  const [ordersByMonth, setOrdersByMonth] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSerialNumber, setSearchSerialNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrdersByMonth();
    fetchStatusOptions();
  }, []);

  const fetchOrdersByMonth = async () => {
    try {
      const response = await api.get('/api/orders/by-month/');
      setOrdersByMonth(response.data);
    } catch (error) {
      console.error('Error fetching orders by month:', error);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await api.get('/api/settings/order_statuses/');
      const options = response.data.map(status => ({
        value: status,
        label: status
      }));
      setStatusOptions(options);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };

  const handleRowClick = (year, monthName) => {
    const monthNumber = getMonthNumber(monthName);
    navigate(`/orders?year=${year}&month=${monthNumber}`);
  };

  const getMonthNumber = (monthName) => {
    const months = {
      'January': '01', 'February': '02', 'March': '03', 'April': '04',
      'May': '05', 'June': '06', 'July': '07', 'August': '08',
      'September': '09', 'October': '10', 'November': '11', 'December': '12'
    };
    return months[monthName] || '';
  };

  const handleSearch = () => {
    const params = new URLSearchParams();
    if (selectedStatus) params.append('status', selectedStatus.value);
    if (searchTerm) params.append('customer', searchTerm);
    if (searchSerialNumber) params.append('serial', searchSerialNumber);
    navigate(`/orders?${params.toString()}`);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '42px',
      borderColor: '#006F3E',
      '&:hover': {
        borderColor: '#005a32',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-4 bg-gray-50 border-b">
        <h2 className="text-2xl font-bold text-[#006F3E]">Dashboard</h2>
        <button
          onClick={() => navigate('/orders')}
          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-6 rounded transition duration-300 ease-in-out w-48"
        >
          All Orders
        </button>
      </div>
      <div className="p-4 bg-gray-100">
        <div className="flex flex-row space-x-4">
          <Select
            options={statusOptions}
            value={selectedStatus}
            onChange={setSelectedStatus}
            placeholder="Filter by Status"
            isClearable
            className="w-1/4"
            styles={selectStyles}
          />
          <input
            type="text"
            placeholder="Search by Customer Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-1/4 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
          />
          <input
            type="text"
            placeholder="Search by Serial Number"
            value={searchSerialNumber}
            onChange={(e) => setSearchSerialNumber(e.target.value)}
            className="w-1/4 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
          />
          <button
            onClick={handleSearch}
            className="w-1/4 bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Search Orders
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#e6f3ee] border-b border-[#b3d9c8]">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Year</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Month</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Total Orders</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {ordersByMonth.map((item, index) => (
                  <tr 
                    key={index} 
                    onClick={() => handleRowClick(item.year, item.month_name)}
                    className="cursor-pointer transition duration-300 ease-in-out hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">{item.year}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">{item.month_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-3 py-1 inline-flex text-base leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {item.total_orders}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {ordersByMonth.length === 0 && (
            <div className="text-center py-4 text-base text-gray-500">No orders found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
