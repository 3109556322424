import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../utils/api';
import Select from 'react-select';
import OrderForm from './OrderForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, orderId: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSerialNumber, setSearchSerialNumber] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [yearFilter, setYearFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const monthOptions = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState(null);

  const fetchInitialData = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const response = await api.get('/api/orders/initial_data/', {
        params: {
          year: queryParams.get('year'),
          month: queryParams.get('month'),
          status: queryParams.get('status'),
          customer: queryParams.get('customer'),
          serial: queryParams.get('serial')
        }
      });

      setYearOptions(response.data.years.map(year => ({
        value: year.toString(),
        label: year.toString()
      })));

      setStatusOptions(response.data.statuses.map(status => ({
        value: status.id.toString(),
        label: status.value
      })));

      setOrders(response.data.orders.results);
      setNextPage(response.data.orders.next ? 2 : null);

      // Set initial filters from URL
      if (queryParams.get('year')) setYearFilter(queryParams.get('year'));
      if (queryParams.get('month')) setMonthFilter(queryParams.get('month'));
      if (queryParams.get('status')) {
        const matchingStatus = response.data.statuses.find(
          s => s.id.toString() === queryParams.get('status')
        );
        setSelectedStatus(matchingStatus ? {
          value: matchingStatus.id.toString(),
          label: matchingStatus.value
        } : null);
      }
      if (queryParams.get('customer')) setSearchTerm(queryParams.get('customer'));
      if (queryParams.get('serial')) setSearchSerialNumber(queryParams.get('serial'));

    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }, [location.search]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    if (statusOptions.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      fetchOrders(
        queryParams.get('year'),
        queryParams.get('month'),
        queryParams.get('status'),
        queryParams.get('customer'),
        queryParams.get('serial')
      );
    }
  }, [location.search, statusOptions]);

  const fetchOrders = async (year, month, status, customer, serial, page = 1) => {
    try {
      const response = await api.get('/api/orders/', {
        params: {
          year,
          month,
          status,
          customer,
          serial,
          page
        }
      });

      if (page === 1) {
        setOrders(response.data.results);
      } else {
        setOrders(prevOrders => [...prevOrders, ...response.data.results]);
      }

      setNextPage(response.data.next ? page + 1 : null);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const refreshOrders = async () => {
    const queryParams = new URLSearchParams(location.search);
    await fetchOrders(
      queryParams.get('year'),
      queryParams.get('month'),
      queryParams.get('status'),
      queryParams.get('customer'),
      queryParams.get('serial')
    );
  };

  const handleEditOrder = async (orderId) => {
    try {
      const response = await api.get(`/api/orders/${orderId}/`, {
        params: {
          fields: 'id,date,status,billing_address,shipping_address,building_info,door_number,so_number'
        }
      });

      if (response.data) {
        setEditingOrder({
          ...response.data,
          building_info: response.data.building_info || {},
          billing_address: response.data.billing_address || {},
          shipping_address: response.data.shipping_address || {},
        });
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
      alert('Error fetching order details. Please try again.');
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await api.delete(`/api/orders/${orderId}/`);
      await refreshOrders();
      setDeleteConfirmation({ show: false, orderId: null });
    } catch (error) {
      console.error('Error deleting order:', error);
      alert('Error deleting order. Please try again.');
    }
  };

  const handleCreateOrder = () => {
    setShowOrderForm(true);
  };

  const handleYearChange = (selectedOption) => {
    setYearFilter(selectedOption ? selectedOption.value : '');
    setMonthFilter('');
    updateURL({ year: selectedOption ? selectedOption.value : '', month: '' });
  };

  const handleMonthChange = (selectedOption) => {
    setMonthFilter(selectedOption ? selectedOption.value : '');
    updateURL({ month: selectedOption ? selectedOption.value : '' });
  };

  const updateURL = useCallback((newParams) => {
    const params = new URLSearchParams(location.search);
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    navigate(`/orders?${params.toString()}`);
  }, [location.search, navigate]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '42px',
      height: '42px',
      backgroundColor: '#f3f4f6',
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: '#9ca3af',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  // Function to format date correctly
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
  };

  const filteredOrders = orders.filter(order => {
    const nameMatch = order.billing_address &&
      order.billing_address.name &&
      order.billing_address.name.toLowerCase().includes(searchTerm.toLowerCase());

    const serialNumberMatch = order.door_number &&
      order.door_number.toLowerCase().includes(searchSerialNumber.toLowerCase());

    const statusMatch = !selectedStatus || (order.status && order.status.value.toString() === selectedStatus.value);

    return (searchTerm.trim() === '' || nameMatch) &&
      (searchSerialNumber.trim() === '' || serialNumberMatch) &&
      statusMatch;
  });

  const handleBackToDashboard = () => {
    navigate('/');
  };

  const getOrdersHeader = () => {
    if (yearFilter && monthFilter) {
      const monthName = monthOptions.find(m => m.value === monthFilter)?.label;
      return `${monthName} ${yearFilter} Orders`;
    } else if (yearFilter) {
      return `${yearFilter} Orders`;
    } else {
      return 'All Orders';
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    updateURL({ status: selectedOption ? selectedOption.value : '' });
  };

  const debouncedSearchRef = useRef(
    debounce((value, type) => {
      updateURL({ [type]: value });
    }, 300)
  );

  const handleCustomerSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearchRef.current(value, 'customer');
  }, []);

  const handleSerialSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchSerialNumber(value);
    debouncedSearchRef.current(value, 'serial');
  }, []);

  useEffect(() => {
    const currentDebounced = debouncedSearchRef.current;

    return () => {
      if (currentDebounced && currentDebounced.cancel) {
        currentDebounced.cancel();
      }
    };
  }, []);

  useEffect(() => {
    debouncedSearchRef.current = debounce((value, type) => {
      updateURL({ [type]: value });
    }, 300);

    return () => {
      if (debouncedSearchRef.current) {
        debouncedSearchRef.current.cancel();
      }
    };
  }, [updateURL]);

  const handleOrderSaved = (savedOrder) => {
    refreshOrders();
    setEditingOrder(null);
    setShowOrderForm(false);
  };

  const handleLoadMore = async () => {
    if (nextPage && !loadingMore) {
      setLoadingMore(true);
      await fetchOrders(
        yearFilter,
        monthFilter,
        selectedStatus?.value,
        searchTerm,
        searchSerialNumber,
        nextPage
      );
      setLoadingMore(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex flex-col p-6 bg-gray-50 border-b">
        <div className="flex justify-between items-center mb-6">
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            onClick={handleBackToDashboard}
          >
            ← Back to Dashboard
          </button>
          <h2 className="text-3xl font-bold text-[#006F3E] mb-0 px-4 py-2 bg-white rounded-lg shadow-sm">
            {getOrdersHeader()}
          </h2>
          <button
            className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            onClick={handleCreateOrder}
          >
            Create New Order
          </button>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <Select
            options={yearOptions}
            value={yearOptions.find(option => option.value === yearFilter)}
            onChange={handleYearChange}
            placeholder="Filter by Year"
            isClearable
            className="w-48"
            styles={{
              ...selectStyles,
              control: (provided) => ({
                ...provided,
                borderColor: '#006F3E',
                '&:hover': {
                  borderColor: '#005a32',
                },
              }),
            }}
          />
          <Select
            options={monthOptions}
            value={monthOptions.find(option => option.value === monthFilter)}
            onChange={handleMonthChange}
            placeholder="Filter by Month"
            isClearable
            className="w-48"
            styles={{
              ...selectStyles,
              control: (provided) => ({
                ...provided,
                borderColor: '#006F3E',
                '&:hover': {
                  borderColor: '#005a32',
                },
              }),
            }}
          />
        </div>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <Select
              options={statusOptions}
              value={selectedStatus}
              onChange={handleStatusChange}
              placeholder="Filter by Status"
              isClearable
              className="w-full"
              styles={{
                ...selectStyles,
                control: (provided) => ({
                  ...provided,
                  borderColor: '#006F3E',
                  '&:hover': {
                    borderColor: '#005a32',
                  },
                }),
              }}
            />
          </div>
          <input
            type="text"
            placeholder="Search by Customer Name"
            value={searchTerm}
            onChange={handleCustomerSearch}
            className="flex-1 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
          />
          <input
            type="text"
            placeholder="Search by Serial Number"
            value={searchSerialNumber}
            onChange={handleSerialSearch}
            className="flex-1 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
          />
        </div>
      </div>
      {(showOrderForm || editingOrder) && (
        <OrderForm
          order={editingOrder}
          onClose={() => {
            setShowOrderForm(false);
            setEditingOrder(null);
          }}
          onOrderSaved={handleOrderSaved}
        />
      )}
      <div className="min-h-[600px]">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#e6f3ee] border-b border-[#b3d9c8]">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Order Status</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Order Date</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Customer Name</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">SO #</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Door #</th>
                  <th className="px-6 py-4 text-center text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredOrders.map((order) => (
                  <tr key={order.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{order.status ? order.status.label : 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(order.date)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.billing_address && order.billing_address.name ? order.billing_address.name : 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.so_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{order.door_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleEditOrder(order.id)}
                          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                        >
                          Edit
                        </button>
                        {deleteConfirmation.show && deleteConfirmation.orderId === order.id ? (
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => handleDeleteOrder(order.id)}
                              className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Confirm
                            </button>
                            <button
                              onClick={() => setDeleteConfirmation({ show: false, orderId: null })}
                              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => setDeleteConfirmation({ show: true, orderId: order.id })}
                            className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredOrders.length === 0 ? (
            <div className="text-center py-4 text-base text-gray-500">
              No orders found
            </div>
          ) : (
            nextPage && (
              <div className="flex justify-center p-4">
                <button
                  onClick={handleLoadMore}
                  disabled={loadingMore}
                  className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out disabled:opacity-50"
                >
                  {loadingMore ? 'Loading...' : 'Load More Orders'}
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderList;
