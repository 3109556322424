let loadPromise = null;

const loadGoogleMapsScript = () => {
    // Return existing promise if script is already loading
    if (loadPromise) {
        return loadPromise;
    }

    // Return immediately if already loaded
    if (window.google && window.google.maps) {
        return Promise.resolve({ success: true });
    }

    loadPromise = new Promise((resolve) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
            if (!apiKey) {
                console.warn('Google Maps API key is not defined');
                resolve({ success: false, reason: 'missing-key' });
                return;
            }

            // Create script element
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initGoogleMaps&loading=async`;
            script.async = true;
            script.defer = true;

            // Define the callback function
            window.initGoogleMaps = () => {
                loadPromise = null;
                delete window.initGoogleMaps;
                resolve({ success: true });
            };

            script.onerror = (error) => {
                loadPromise = null;
                delete window.initGoogleMaps;
                console.warn('Failed to load Google Maps script:', error);
                resolve({ success: false, reason: 'load-error' });
            };

            document.head.appendChild(script);
        } catch (error) {
            loadPromise = null;
            console.warn('Error loading Google Maps script:', error);
            resolve({ success: false, reason: 'script-error' });
        }
    });

    return loadPromise;
};

export default loadGoogleMapsScript;