import React, { useState } from 'react';
import api from '../utils/api';

const PDFUploader = ({
    orderId,
    currentBlueprint,
    currentBlueprintName,
    uploadEndpoint = 'blueprint',
    uploaderId,
    onUploadComplete,
    onFileSelected,
    className = '',
    variant = 'default',
    selectedFile,
}) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!file.type.includes('pdf')) {
            setError('Please upload PDF files only');
            return;
        }

        if (file.size > 50 * 1024 * 1024) {
            setError('File size must be less than 50MB');
            return;
        }

        setError(null);
        if (onFileSelected) {
            onFileSelected(file, uploadEndpoint);
        }

        if (!orderId) {
            setError('The file will be uploaded when the order is saved.');
        } else {
            setIsUploading(true);
        }

        if (orderId) {
            setIsUploading(true);
            const formData = new FormData();
            const fieldName = uploadEndpoint === 'blueprint' ? 'blueprint' : 'door_placement_drawing';
            formData.append(fieldName, file);

            try {
                const response = await api.post(
                    `/api/orders/${orderId}/${uploadEndpoint}/`,
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    }
                );

                onUploadComplete && onUploadComplete(response);
                setUploadProgress(0);
                setError(null);
            } catch (error) {
                console.error('Upload failed:', error);
                setError(`Failed to upload: ${error.message}`);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handlePreview = () => {
        if (orderId) {
            const apiUrl = process.env.REACT_APP_API_URL || 'https://test.rocksoliddata.solutions';
            const previewEndpoint = uploadEndpoint === 'blueprint' ? 'serve-blueprint' : 'serve-door-placement';
            window.open(`${apiUrl}/api/orders/${orderId}/${previewEndpoint}/`, '_blank');
        }
    };

    const removeFile = async (e) => {
        e.preventDefault();
        setError(null);

        if (!orderId) return;

        try {
            const removeEndpoint = uploadEndpoint === 'blueprint' ? 'remove-blueprint' : 'remove-door-placement';
            await api.delete(
                `/api/orders/${orderId}/${removeEndpoint}/`
            );

            onUploadComplete && onUploadComplete({
                data: {
                    [uploadEndpoint === 'blueprint' ? 'blueprint' : 'door_placement_drawing']: null,
                    [uploadEndpoint === 'blueprint' ? 'blueprint_filename' : 'door_placement_filename']: null
                }
            });

        } catch (error) {
            console.error('Remove file error:', error);
            setError(`Failed to remove file: ${error.message}`);
        }
    };

    const getDisplayName = () => {
        if (selectedFile?.name) {
            return selectedFile.name;
        }
        if (currentBlueprintName) {
            return currentBlueprintName;
        }
        return '';
    };

    const shouldShowFileActions = () => {
        return Boolean(selectedFile) || Boolean(currentBlueprintName);
    };

    return (
        <div className={`relative ${className}`}>
            <div className="mt-1">
                {shouldShowFileActions() ? (
                    <div className={`px-3 py-1 border-2 border-[#006F3E] rounded-md ${variant === 'compact' ? 'h-[38px]' : 'h-[200px]'} flex items-center bg-white`}>
                        <div className="flex items-center justify-between w-full">
                            <span className="truncate text-sm">
                                {getDisplayName()}
                            </span>
                            <div className="flex items-center space-x-2">
                                {isUploading && (
                                    <div className="w-16 bg-gray-200 rounded-full h-1.5 mr-2">
                                        <div
                                            className="bg-[#006F3E] h-1.5 rounded-full"
                                            style={{ width: `${uploadProgress}%` }}
                                        ></div>
                                    </div>
                                )}
                                {currentBlueprint && !selectedFile && (
                                    <>
                                        <button
                                            onClick={handlePreview}
                                            type="button"
                                            className="text-blue-500 hover:text-blue-700 text-sm"
                                        >
                                            Open
                                        </button>
                                        <button
                                            onClick={removeFile}
                                            disabled={isUploading}
                                            type="button"
                                            className="text-red-500 hover:text-red-700 disabled:text-gray-400 text-sm"
                                        >
                                            Remove
                                        </button>
                                    </>
                                )}
                                {selectedFile && (
                                    <button
                                        onClick={() => onFileSelected && onFileSelected(null, uploadEndpoint)}
                                        type="button"
                                        className="text-red-500 hover:text-red-700 text-sm"
                                    >
                                        Remove
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`flex items-center justify-center px-3 py-1 border-2 border-[#006F3E] border-dashed rounded-md ${variant === 'compact' ? 'h-[38px]' : 'h-[200px]'} bg-white`}>
                        <div className="flex items-center space-x-2">
                            <svg
                                className={`${variant === 'compact' ? 'h-5 w-5' : 'h-8 w-8'} text-gray-400`}
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <label
                                htmlFor={uploaderId}
                                className="relative cursor-pointer rounded-md font-medium text-[#006F3E] hover:text-[#005c34] focus-within:outline-none text-sm"
                            >
                                <span>Upload PDF</span>
                                <input
                                    id={uploaderId}
                                    name={uploaderId}
                                    type="file"
                                    accept=".pdf,application/pdf"
                                    className="sr-only"
                                    onChange={handleFileChange}
                                    disabled={isUploading}
                                />
                            </label>
                        </div>
                    </div>
                )}

                {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                )}
            </div>
        </div>
    );
};

export default PDFUploader;
