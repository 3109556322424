import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Select from 'react-select';

const BUILDING_TYPE_CHOICES = [
  { value: 'DOOR_ONLY', label: 'Door Only' },
  { value: 'FREEZER_COOLER_UNIT', label: 'Freezer/Cooler Unit' },
  { value: 'ICE_HOUSE_KIT', label: 'Ice House Kit' },
  { value: 'PREBUILT_ICE_HOUSE', label: 'Prebuilt Ice House' },
];

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [components, setComponents] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, productId: null });
  const [error, setError] = useState(null);
  const { register, control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      description: '',
      building_type: null,
      components: []
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "components"
  });

  useEffect(() => {
    fetchProducts();
    fetchComponents();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await api.get('/api/products/');
      setProducts(response.data);
    } catch (error) {
      setError('Failed to fetch products');
    }
  };

  const fetchComponents = async () => {
    try {
      const response = await api.get('/api/components/');
      setComponents(response.data.map(comp => ({ value: comp.id, label: comp.name, ...comp })));
    } catch (error) {
      setError('Failed to fetch components');
    }
  };

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        ...data,
        building_type: data.building_type.value,
        components: data.components.map(comp => ({
          component: comp.component,
          quantity: parseInt(comp.quantity)
        }))
      };
      if (editingProduct) {
        await api.put(`/api/products/${editingProduct.id}/`, formattedData);
        setEditingProduct(null);
      } else {
        await api.post('/api/products/', formattedData);
      }
      reset({
        description: '',
        building_type: null,
        components: []
      });
      fetchProducts();
    } catch (error) {
      setError('Failed to save product');
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setValue("description", product.description);
    setValue("building_type", BUILDING_TYPE_CHOICES.find(type => type.value === product.building_type));
    setValue("components", product.components.map(comp => ({
      component: comp.component,
      quantity: comp.quantity
    })));
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/products/${id}/`);
      fetchProducts();
      setDeleteConfirmation({ show: false, productId: null });
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const calculateTotalCost = (productComponents) => {
    return productComponents.reduce((total, comp) => {
      const component = components.find(c => c.id === comp.component);
      const cost = component ? parseFloat(component.cost) : 0;
      const quantity = parseFloat(comp.quantity) || 0;
      return total + (cost * quantity);
    }, 0);
  };

  const calculatePrice = (productComponents) => {
    return productComponents.reduce((total, comp) => {
      const component = components.find(c => c.id === comp.component);
      const cost = component ? parseFloat(component.cost) : 0;
      const markup = component ? parseFloat(component.markup) : 0;
      const quantity = parseFloat(comp.quantity) || 0;
      const componentPrice = cost * (1 + markup / 100);
      return total + (componentPrice * quantity);
    }, 0);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '42px',
      borderColor: '#006F3E',
      '&:hover': {
        borderColor: '#005a32',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const ComponentList = ({ components }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-[#006F3E] hover:text-[#005a32] font-medium"
        >
          {isExpanded ? 'Hide' : 'Show'} {components.length} component{components.length !== 1 ? 's' : ''}
        </button>
        {isExpanded && (
          <ul className="mt-2 space-y-1">
            {components.map(comp => (
              <li key={comp.id} className="text-sm">
                {comp.component_name}: {comp.quantity}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-6 bg-gray-50 border-b">
        <h2 className="text-2xl font-bold text-[#006F3E]">Products</h2>
        <button
          onClick={handleSubmit(onSubmit)}
          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          {editingProduct ? 'Update Product' : 'Add Product'}
        </button>
      </div>
      <div className="p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
          <div className="flex mb-4 space-x-4">
            <div className="flex-grow">
              <input
                {...register("description", { required: "Description is required" })}
                placeholder="Product Description"
                className="w-full px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
              />
              {errors.description && <p className="text-red-500 text-xs italic mt-1">{errors.description.message}</p>}
            </div>
            <div className="w-1/3">
              <Controller
                name="building_type"
                control={control}
                rules={{ required: "Building Type is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={BUILDING_TYPE_CHOICES}
                    placeholder="Select Building Type"
                    className="w-full"
                    styles={selectStyles}
                  />
                )}
              />
              {errors.building_type && <p className="text-red-500 text-xs italic mt-1">{errors.building_type.message}</p>}
            </div>
          </div>
          <div className="mb-4">
            {fields.map((field, index) => (
              <div key={field.id} className="flex items-center mb-2">
                <Select
                  options={components}
                  value={components.find(c => c.id === watch(`components.${index}.component`))}
                  onChange={(selectedOption) => {
                    setValue(`components.${index}.component`, selectedOption.id);
                  }}
                  className="w-1/3 mr-2"
                  styles={selectStyles}
                />
                <input
                  {...register(`components.${index}.quantity`, {
                    valueAsNumber: true,
                    min: 1,
                    onChange: (e) => setValue(`components.${index}.quantity`, parseInt(e.target.value) || 1)
                  })}
                  type="number"
                  className="w-1/6 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 mr-2"
                  defaultValue={1}
                />
                <div className="w-1/6 px-3 py-2 bg-[#e6f3ee] rounded-md text-[#006F3E] mr-2 border border-[#b3d9c8]">
                  Cost: $
                  {(() => {
                    const component = components.find(c => c.id === watch(`components.${index}.component`));
                    const quantity = watch(`components.${index}.quantity`) || 1;
                    return component ? (component.cost * quantity).toFixed(2) : '0.00';
                  })()}
                </div>
                <div className="w-1/6 px-3 py-2 bg-[#e6f3ee] rounded-md text-[#006F3E] mr-2 border border-[#b3d9c8]">
                  Price: $
                  {(() => {
                    const component = components.find(c => c.id === watch(`components.${index}.component`));
                    const quantity = watch(`components.${index}.quantity`) || 1;
                    return component
                      ? (component.cost * (1 + component.markup / 100) * quantity).toFixed(2)
                      : '0.00';
                  })()}
                </div>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => append({ component: '', quantity: 1 })}
              className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out mt-2"
            >
              Add Component
            </button>
          </div>
          <div className="mb-4 text-[#006F3E]">
            <p>Total Cost: ${calculateTotalCost(watch('components')).toFixed(2)}</p>
            <p>Total Price: ${calculatePrice(watch('components')).toFixed(2)}</p>
          </div>
        </form>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#e6f3ee] border-b border-[#b3d9c8]">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Description</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Building Type</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Components</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Total Cost</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Price</th>
                  <th className="px-6 py-4 text-center text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {products.map((product) => (
                  <tr key={product.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">{product.description}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">
                      {BUILDING_TYPE_CHOICES.find(type => type.value === product.building_type)?.label || product.building_type}
                    </td>
                    <td className="px-6 py-4 text-base text-gray-900">
                      <ComponentList components={product.components} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">${calculateTotalCost(product.components).toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">${calculatePrice(product.components).toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleEdit(product)}
                          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                        >
                          Edit
                        </button>
                        {deleteConfirmation.show && deleteConfirmation.productId === product.id ? (
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => handleDelete(product.id)}
                              className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Confirm
                            </button>
                            <button
                              onClick={() => setDeleteConfirmation({ show: false, productId: null })}
                              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => setDeleteConfirmation({ show: true, productId: product.id })}
                            className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {products.length === 0 && (
            <div className="text-center py-4 text-base text-gray-500">No products found</div>
          )}
        </div>
      </div>
      {error && (
        <div className="text-red-500 bg-red-100 p-3 rounded mb-4">
          {error}
        </div>
      )}
    </div>
  );
};

export default ProductList;
