import React, { useState, useEffect, useMemo, useRef } from 'react';
import api from '../utils/api';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import PDFUploader from './PDFUploader';
import AddressAutocomplete from './AddressAutocomplete';

const BUILDING_TYPE_CHOICES = [
  { value: 'DOOR_ONLY', label: 'Door Only' },
  { value: 'FREEZER_COOLER_UNIT', label: 'Freezer/Cooler Unit' },
  { value: 'ICE_HOUSE_KIT', label: 'Ice House Kit' },
  { value: 'PREBUILT_ICE_HOUSE', label: 'Prebuilt Ice House' },
];

const HINGE_SIDE_OPTIONS = [
  { value: 'HOR', label: 'HOR' },
  { value: 'HOL', label: 'HOL' },
];

const formatAdditionalItem = (item, quantity = 1) => ({
  item: item,
  quantity: quantity
});

const OrderForm = ({ order, onClose, onOrderSaved }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      status: null,
      date: order?.date || new Date().toISOString().split('T')[0],
      ship_date: order?.ship_date || '',
      so_number: order?.so_number || '',
      invoice_number: order?.invoice_number || '',
      door_number: order?.door_number || '',
      ship_via: null,
      special_delivery_info: order?.special_delivery_info || '',
      billing_address: order?.billing_address || {
        name: '',
        street: '',
        city: '',
        state: null,
        zip_code: '',
      },
      shipping_address: order?.shipping_address || {
        name: '',
        street: '',
        city: '',
        state: null,
        zip_code: '',
      },
      building_info: order?.building_info || {
        size: null,
        thickness: null,
        building_type: order?.building_info?.building_type
          ? BUILDING_TYPE_CHOICES.find(choice => choice.value === order.building_info.building_type)
          : null,
        door_type: null,
        hinge_side: null,
        door_cladding: null,
        roof_color: null,
        siding_color: null,
        door_placement: '',
        description: null,
        is_custom: false,
      },
      phone: order?.phone || '',
      eps_po_number: order?.eps_po_number || '',
      po_delivery_date: order?.po_delivery_date || '',
      price: order?.price || '',
      freight_cost: order?.freight_cost || '',
      additional_items: [],
      billing_zip_code: order?.billing_address?.zip_code || '',
      shipping_zip_code: order?.shipping_address?.zip_code || '',
      notes: order?.notes || '',
    }
  });

  const [settings, setSettings] = useState({
    order_status: [],
    ship_via: [],
    size: [],
    thickness: [],
    building_type: [],
    door_type: [],
    hinge_side: [],
    door_cladding: [],
    roof_color: [],
    siding_color: [],
    additional_item: [],
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [buildingDescriptions, setBuildingDescriptions] = useState([]);
  const [shipViaOptions, setShipViaOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  // Update state declarations for PDFs
  const [selectedBlueprint, setSelectedBlueprint] = useState(null);
  const [selectedDoorPlacement, setSelectedDoorPlacement] = useState(null);
  const [currentPDF, setCurrentPDF] = useState({
    blueprint: order?.blueprint || null,
    blueprint_filename: order?.blueprint_filename || null
  });
  const [doorPlacementPDF, setDoorPlacementPDF] = useState({
    door_placement_drawing: order?.door_placement_drawing || null,
    door_placement_filename: order?.door_placement_filename || null
  });

  // Add refs to persist file data across re-renders
  const selectedBlueprintRef = useRef(null);
  const selectedDoorPlacementRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse, stateChoicesResponse, productsResponse, shipViaResponse] = await Promise.all([
          api.get('/api/settings/'),
          api.get('/api/customers/state_choices/'),
          api.get('/api/products/'),
          api.get('/api/settings/ship_via_options/')
        ]);

        const settingsData = settingsResponse.data.reduce((acc, setting) => {
          if (!acc[setting.category]) {
            acc[setting.category] = [];
          }
          acc[setting.category].push({
            value: setting.id.toString(),
            label: setting.value
          });
          return acc;
        }, {});
        setSettings(settingsData);

        const stateChoices = stateChoicesResponse.data;
        setStateOptions(Object.entries(stateChoices).map(([value, label]) => ({ value, label })));

        const descriptions = productsResponse.data.map(product => ({
          value: product.id,
          label: product.description,
          buildingType: product.building_type,
          buildingPrice: product.price
        }));
        descriptions.push({ value: 'custom', label: 'Custom', buildingType: '', buildingPrice: '' });
        setBuildingDescriptions(descriptions);

        const shipViaOptionsData = shipViaResponse.data.map(option => ({
          value: option.id,
          label: option.value
        }));
        setShipViaOptions(shipViaOptionsData);
        setOptionsLoaded(true);

        // Set the status if we have an existing order
        if (order && order.status && settingsData.order_status) {
          const matchingStatus = settingsData.order_status.find(
            status => status.value === order.status.value.toString()
          );
          if (matchingStatus) {
            setValue('status', matchingStatus);
          }
        }

        if (order && order.ship_via) {
          const selectedOption = shipViaOptionsData.find(opt => opt.value === order.ship_via.value);
          setValue('ship_via', selectedOption || null);
        }

        // Set the building description if we have an existing order
        if (order && order.building_info && order.building_info.description) {
          const matchingDescription = descriptions.find(
            desc => desc.value === order.building_info.description
          );
          if (matchingDescription) {
            setValue('building_info.description', matchingDescription);
          }
        }

        // Set the hinge side if we have an existing order
        if (order && order.building_info && order.building_info.hinge_side) {
          const hingeSideOption = { value: order.building_info.hinge_side, label: order.building_info.hinge_side };
          setValue('building_info.hinge_side', hingeSideOption);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [order, setValue]);

  useEffect(() => {
    if (order) {
      setCurrentPDF({
        blueprint: order.blueprint || null,
        blueprint_filename: order.blueprint_filename || null
      });
      setDoorPlacementPDF({
        door_placement_drawing: order.door_placement_drawing || null,
        door_placement_filename: order.door_placement_filename || null
      });
    }
  }, [order]);

  const setFormValues = React.useCallback(() => {
    if (!order || !optionsLoaded) return;

    Object.keys(order).forEach(key => {
      if (key === 'status') {
        setValue('status', order.status || null);
      } else if (key === 'building_info' && order.building_info) {
        Object.keys(order.building_info).forEach(infoKey => {
          const value = order.building_info[infoKey];
          if (['door_type', 'hinge_side', 'door_cladding', 'size', 'thickness', 'building_type', 'roof_color', 'siding_color'].includes(infoKey)) {
            setValue(`building_info.${infoKey}`, value ? { value, label: value } : null);
          } else if (infoKey === 'description') {
            // Skip setting description here as it's handled in the useEffect
          } else {
            setValue(`building_info.${infoKey}`, value ?? '');
          }
        });
      } else if (key === 'billing_address' && order.billing_address) {
        Object.keys(order.billing_address).forEach(addressKey => {
          if (addressKey === 'state') {
            const selectedState = stateOptions.find(
              (option) => option.value === order.billing_address.state
            );
            setValue('billing_address.state', selectedState || null);
          } else {
            setValue(`billing_address.${addressKey}`, order.billing_address[addressKey] ?? '');
          }
        });
      } else if (key === 'shipping_address' && order.shipping_address) {
        Object.keys(order.shipping_address).forEach(addressKey => {
          if (addressKey === 'state') {
            const selectedState = stateOptions.find(
              (option) => option.value === order.shipping_address.state
            );
            setValue('shipping_address.state', selectedState || null);
          } else {
            setValue(`shipping_address.${addressKey}`, order.shipping_address[addressKey] ?? '');
          }
        });
      } else if (key === 'additional_items') {
        setValue('additional_items', Array.isArray(order.additional_items) ? order.additional_items : []);
      } else if (key === 'ship_via' && order.ship_via) {
        // skip setting ship_via here to avoid overwriting
      } else {
        setValue(key, order[key] ?? '');
      }
    });

    // Set building info values
    if (order.building_info) {
      Object.entries(order.building_info).forEach(([key, value]) => {
        if (value !== null) {
          if (key === 'building_type') {
            const buildingTypeOption = BUILDING_TYPE_CHOICES.find(choice => choice.value === value);
            setValue('building_info.building_type', buildingTypeOption || null);
          } else if (key === 'hinge_side') {
            const hingeSideOption = HINGE_SIDE_OPTIONS.find(opt => opt.value === value);
            setValue('building_info.hinge_side', hingeSideOption || null);
          } else if (['size', 'thickness', 'door_type', 'door_cladding', 'roof_color', 'siding_color'].includes(key)) {
            const option = settings[key].find(opt => opt.value === value.toString());
            setValue(`building_info.${key}`, option || null);
          } else if (key === 'description') {
            if (order.building_info.is_custom) {
              setValue('building_info.description', { value: 'custom', label: 'Custom' });
            } else {
              const description = buildingDescriptions.find(desc => desc.value === value);
              setValue('building_info.description', description || null);
            }
          } else if (key === 'is_custom') {
            setValue('building_info.is_custom', value);
            if (value) {
              setValue('building_info.description', { value: 'custom', label: 'Custom' });
            }
          } else if (key === 'additional_items') {
            const formattedItems = value.map(itemId =>
              settings.additional_item.find(item => item.value === itemId.toString())
            ).filter(Boolean);
            setValue('additional_items', formattedItems);
          } else {
            setValue(`building_info.${key}`, value);
          }
        }
      });

      // Handle additional items
      if (order.building_info.additional_items?.length > 0 && settings.additional_item) {
        const formattedItems = order.building_info.additional_items.map(item => ({
          item: settings.additional_item.find(opt => opt.value === item.item.toString()),
          quantity: item.quantity
        })).filter(item => item.item); // Filter out any items that weren't found in settings
        
        setValue('additional_items', formattedItems);
      }
    }
  }, [order, optionsLoaded, setValue, stateOptions, settings, buildingDescriptions]);

  useEffect(() => {
    if (order && optionsLoaded && settings.additional_item.length > 0) {
      setFormValues();
    }
  }, [order, optionsLoaded, settings.additional_item, setFormValues]);

  const validatePhoneNumber = (value) => {
    if (value === '') return true;
    const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
    return phoneRegex.test(value) || "Invalid phone number format. Use (XXX) XXX-XXXX";
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const formatZipCode = (value) => {
    const digits = value.replace(/[^\d]/g, '');
    if (digits.length <= 5) return digits;
    return `${digits.slice(0, 5)}-${digits.slice(5, 9)}`;
  };

  const onSubmit = async (data) => {
    try {
      console.log("onSubmit started, isPrinting:", data.isPrinting);
      
      // Remove the isPrinting flag from the data before sending to API
      const { isPrinting: isCurrentlyPrinting, ...submitData } = data;

      // Function to format date or return null if empty
      const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      };

      // Remove both file fields from orderData since they're handled separately by PDFUploader
      const { blueprint, blueprint_filename, door_placement_drawing, door_placement_filename, ...restData } = submitData;

      // Format additional items consistently
      const formattedAdditionalItems = restData.additional_items?.map(item => ({
        item: item.item?.value || item.item,  // Get just the value/id
        quantity: parseInt(item.quantity) || 1
      })) || [];

      // Prepare the main order data
      const orderData = {
        ...restData,
        status: restData.status?.value || null,
        ship_via: restData.ship_via?.value || null,
        ship_date: formatDate(restData.ship_date),
        po_delivery_date: formatDate(restData.po_delivery_date),
        building_info: {
          ...restData.building_info,
          size: restData.building_info.size?.value || null,
          thickness: restData.building_info.thickness?.value || null,
          building_type: restData.building_info.building_type?.value || null,
          door_type: restData.building_info.door_type?.value || null,
          hinge_side: restData.building_info.hinge_side?.value || null,
          door_cladding: restData.building_info.door_cladding?.value || null,
          roof_color: restData.building_info.roof_color?.value || null,
          siding_color: restData.building_info.siding_color?.value || null,
          door_placement: restData.building_info.door_placement || '',
          description: restData.building_info.description?.value === 'custom' ? null : restData.building_info.description?.value,
          additional_items: formattedAdditionalItems,
          is_custom: restData.building_info.description?.value === 'custom',
        },
        billing_address: {
          ...watch('billing_address'),
          state: restData.billing_address.state?.value || '',
        },
        shipping_address: {
          ...watch('shipping_address'),
          state: restData.shipping_address.state?.value || '',
        },
        additional_items: undefined
      };

      let response;
      if (order) {
        response = await api.put(`/api/orders/${order.id}/`, orderData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        response = await api.post('/api/orders/', orderData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      const savedOrder = response.data;

      // Add new PDF upload logic using refs
      try {
        // Upload blueprint if selected
        if (selectedBlueprintRef.current) {
          const blueprintFormData = new FormData();
          blueprintFormData.append('blueprint', selectedBlueprintRef.current);
          await api.post(
            `/api/orders/${savedOrder.id}/blueprint/`,
            blueprintFormData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
        }

        // Upload door placement if selected
        if (selectedDoorPlacementRef.current) {
          const doorPlacementFormData = new FormData();
          doorPlacementFormData.append('door_placement_drawing', selectedDoorPlacementRef.current);
          await api.post(
            `/api/orders/${savedOrder.id}/door-placement-drawing/`,
            doorPlacementFormData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
        }
      } catch (uploadError) {
        console.error('Error uploading PDFs:', uploadError);
        // Continue with order save even if PDF upload fails
      }

      // Check the printing flag from the data object instead of the state
      console.log("Before close check, isPrinting:", isCurrentlyPrinting);
      if (!isCurrentlyPrinting) {
        console.log("Closing modal...");
        onOrderSaved(savedOrder);
        onClose();
      } else {
        console.log("Not closing modal due to printing");
      }

      return response;
    } catch (error) {
      console.error('Error saving order:', error);
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        Object.keys(serverErrors).forEach(key => {
          setError(key, { type: 'manual', message: serverErrors[key][0] });
        });
      }
    }
  };

  const renderField = (name, label, type = "text", required = false) => (
    <Controller
      key={name}
      name={name}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
      render={({ field }) => (
        <div>
          <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}:{required && <span className="text-red-500">*</span>}</label>
          <input
            {...field}
            value={field.value || ''}
            type={type}
            className={`mt-1 block w-full py-2 px-3 border ${required ? 'border-red-500' : 'border-[#006F3E]'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm`}
          />
          {errors[name] && <p className="mt-1 text-sm text-red-600">{errors[name].message}</p>}
        </div>
      )}
    />
  );

  const renderSelect = (name, label, options = [], required = false) => (
    <Controller
      key={name}
      name={name}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
      render={({ field }) => {
        // Check if the value exists in the options
        const isValueInOptions =
          options && options.some((option) => option.value === field.value?.value);

        // If options are not loaded or value isn't in options, set value to null
        const value = isValueInOptions ? field.value : null;

        return (
          <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
              {label}:{required && <span className="text-red-500">*</span>}
            </label>
            <Select
              inputId={name}
              options={options}
              value={value}
              onChange={(selectedOption) => field.onChange(selectedOption)}
              onBlur={field.onBlur}
              name={field.name}
              ref={field.ref}
              className={`mt-1 ${required ? 'border-red-500' : ''}`}
              classNamePrefix="select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: required ? '#EF4444' : '#006F3E',
                  '&:hover': {
                    borderColor: required ? '#DC2626' : '#005a32',
                  },
                }),
                container: (provided) => ({
                  ...provided,
                  width: '100%', // This ensures the Select component takes full width of its parent
                }),
              }}
            />
            {errors[name] && (
              <p className="mt-1 text-sm text-red-600">{errors[name].message}</p>
            )}
          </div>
        );
      }}
    />
  );

  const renderAddressFields = (type) => (
    <div className="space-y-2 relative">
      <div>
        <label htmlFor={`${type}_address_autocomplete`} className="block text-sm font-medium text-gray-700">
          Address Lookup:
        </label>
        <AddressAutocomplete
          onSelect={(address) => {
            setValue(`${type}_address.street`, address.street);
            setValue(`${type}_address.city`, address.city);
            setValue(`${type}_address.state`, address.state);
            setValue(`${type}_address.zip_code`, address.zip_code);
          }}
          className="mt-1 block w-full py-2 px-3 border border-[#006F3E] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm"
        />
      </div>

      {/* Keep your existing address fields as fallback/manual entry */}
      {['name', 'street', 'city'].map((field) => (
        <div key={`${type}_${field}`}>
          <Controller
            name={`${type}_address.${field}`}
            control={control}
            defaultValue=""
            render={({ field: inputField }) => (
              <>
                <label htmlFor={`${type}_${field}`} className="block text-sm font-medium text-gray-700">
                  {field.charAt(0).toUpperCase() + field.slice(1)}:{type === 'billing' && field === 'name' && <span className="text-red-500">*</span>}
                </label>
                <input
                  {...inputField}
                  id={`${type}_${field}`}
                  className={`mt-1 block w-full py-2 px-3 border ${type === 'billing' && field === 'name' ? 'border-red-500' : 'border-[#006F3E]'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm`}
                  required={type === 'billing' && field === 'name'}
                />
              </>
            )}
          />
        </div>
      ))}
      <div className="flex space-x-2">
        <div className="w-1/2">
          <Controller
            name={`${type}_address.state`}
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <>
                <label htmlFor={`${type}_state`} className="block text-sm font-medium text-gray-700">State:</label>
                <Select
                  {...field}
                  inputId={`${type}_state`}
                  options={stateOptions}
                  className="mt-1"
                  classNamePrefix="select"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: '#006F3E',
                      '&:hover': {
                        borderColor: '#005a32',
                      },
                    }),
                  }}
                />
              </>
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            name={`${type}_address.zip_code`}
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^\d{5}(-\d{4})?$/,
                message: "Enter a valid zip code in the format XXXXX or XXXXX-XXXX"
              }
            }}
            render={({ field }) => (
              <>
                <label htmlFor={`${type}_zip_code`} className="block text-sm font-medium text-gray-700">ZIP Code:</label>
                <input
                  {...field}
                  id={`${type}_zip_code`}
                  type="text"
                  className={`mt-1 block w-full py-2 px-3 border ${errors[`${type}_address.zip_code`] ? 'border-red-500' : 'border-[#006F3E]'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm`}
                  maxLength={10}
                  onChange={(e) => {
                    const formatted = formatZipCode(e.target.value);
                    field.onChange(formatted);
                  }}
                />
                {errors[`${type}_address.zip_code`] && (
                  <p className="mt-1 text-sm text-red-600">{errors[`${type}_address.zip_code`].message}</p>
                )}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );

  const watchedDescription = watch('building_info.description');
  const watchedBuildingType = watch('building_info.building_type');

  const showSizeAndThickness = useMemo(() => {
    const isCustom = watchedDescription?.value === 'custom';
    const validBuildingTypes = ['FREEZER_COOLER_UNIT', 'ICE_HOUSE_KIT', 'PREBUILT_ICE_HOUSE'];
    return isCustom && validBuildingTypes.includes(watchedBuildingType?.value);
  }, [watchedDescription, watchedBuildingType]);

  const handlePrint = async () => {
    try {
      console.log("in handlePrint before setIsPrinting, isPrinting", isPrinting);
      setIsPrinting(true);
      
      // Wait for state to update
      await new Promise(resolve => setTimeout(resolve, 0));
      
      console.log("in handlePrint after setIsPrinting, isPrinting", isPrinting);
      
      // Get current form data
      const formData = watch();

      // Add a flag to the form data to indicate we're printing
      formData.isPrinting = true;

      // Save the form data first
      const saveResponse = await onSubmit(formData);

      // Rest of the print logic...
      const orderId = order?.id || saveResponse?.data?.id;

      if (orderId) {
        const pdfResponse = await api.get(
          `/api/orders/${orderId}/print/`,
          { responseType: 'blob' }
        );

        const file = new Blob([pdfResponse.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open(fileURL, '_blank');
        
        if (newWindow) {
          const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(checkWindowClosed);
              setIsPrinting(false);
            }
          }, 1000);

          setTimeout(() => {
            clearInterval(checkWindowClosed);
            setIsPrinting(false);
            URL.revokeObjectURL(fileURL);
          }, 60000);
        } else {
          setIsPrinting(false);
          alert('Please allow pop-ups to view the PDF');
        }
      } else {
        console.error('No order ID available');
        setIsPrinting(false);
      }
    } catch (error) {
      console.error('Error in handlePrint:', error);
      setIsPrinting(false);
      alert('There was an error generating the PDF. Please try again.');
    }
  };

  // Add a useEffect to monitor isPrinting changes
  useEffect(() => {
    console.log("isPrinting changed to:", isPrinting);
  }, [isPrinting]);

  const handleClose = () => {
    onClose();
  };
  const handleUseBillingAddress = () => {
    const billingAddressData = watch('billing_address');

    // Copy individual fields
    setValue('shipping_address.name', billingAddressData.name || '');
    setValue('shipping_address.street', billingAddressData.street || '');
    setValue('shipping_address.city', billingAddressData.city || '');
    setValue('shipping_address.zip_code', billingAddressData.zip_code || '');

    // Handle the state field separately
    const stateValue = billingAddressData.state;
    setValue('shipping_address.state', stateValue || null);
  };

  // Update the file selection handler to use refs
  const handleFileSelected = (file, uploadEndpoint) => {
    if (uploadEndpoint === 'blueprint') {
      selectedBlueprintRef.current = file;
      setSelectedBlueprint(file);
      setCurrentPDF({
        blueprint: null,
        blueprint_filename: file?.name || null
      });
    } else if (uploadEndpoint === 'door-placement-drawing') {
      selectedDoorPlacementRef.current = file;
      setSelectedDoorPlacement(file);
      setDoorPlacementPDF({
        door_placement_drawing: null,
        door_placement_filename: file?.name || null
      });
    }
  };

  const handleMapSearch = (addressType) => {
    const address = watch(`${addressType}_address`);
    console.log('Address data:', address); // Add debugging

    // Validate that we have minimum required address data
    if (!address?.street || !address?.city) {
      alert('Please enter at least street and city for the address search.');
      return;
    }

    const addressString = [
      address.street,
      address.city,
      address.state?.label || address.state, // Handle both object and string state values
      address.zip_code
    ].filter(Boolean).join(', ');

    console.log('Search query:', addressString); // Add debugging

    if (addressString) {
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;
      console.log('Opening URL:', mapsUrl); // Add debugging
      window.open(mapsUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const renderAdditionalItems = () => (
    <div>
      <label htmlFor="additional_items" className="block text-sm font-medium text-gray-700">
        Additional Items:
      </label>
      <Controller
        name="additional_items"
        control={control}
        render={({ field }) => (
          <div className="space-y-2">
            {field.value.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="flex-grow">
                  <Select
                    value={item.item}
                    onChange={(newValue) => {
                      const newItems = [...field.value];
                      newItems[index] = { ...newItems[index], item: newValue };
                      field.onChange(newItems);
                    }}
                    options={settings.additional_item}
                    className="basic-single"
                    classNamePrefix="select"
                  />
                </div>
                <div className="w-24">
                  <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) => {
                      const newItems = [...field.value];
                      newItems[index] = { ...newItems[index], quantity: parseInt(e.target.value) || 1 };
                      field.onChange(newItems);
                    }}
                    className="w-full py-2 px-3 border border-[#006F3E] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const newItems = field.value.filter((_, i) => i !== index);
                    field.onChange(newItems);
                  }}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => {
                field.onChange([...field.value, formatAdditionalItem(null)]);
              }}
              className="mt-2 inline-flex items-center px-3 py-2 border border-[#006F3E] text-sm leading-4 font-medium rounded-md text-[#006F3E] bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#006F3E]"
            >
              <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Add Item
            </button>
          </div>
        )}
      />
    </div>
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full p-4">
      <div className="relative mx-auto p-5 border w-full max-w-6xl shadow-lg rounded-md bg-white">
        <div className="absolute top-0 right-0 mt-4 mr-4">
          <button
            onClick={handleClose}
            className="text-gray-600 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-full p-2 transition duration-150 ease-in-out"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="w-48">
            <Controller
              name="status"
              control={control}
              rules={{ required: "Status is required" }}
              render={({ field }) => (
                <div>
                  <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                    Status:<span className="text-red-500">*</span>
                  </label>
                  <Select
                    {...field}
                    options={settings.order_status}
                    placeholder="Select Status"
                    isSearchable
                    isClearable={false}
                    className={`w-full mt-1 ${errors.status ? 'border-red-500' : ''}`}
                    classNamePrefix="select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: errors.status ? '#EF4444' : '#006F3E',
                        backgroundColor: '#f0fdf4',
                        '&:hover': {
                          borderColor: errors.status ? '#DC2626' : '#005a32',
                        },
                      }),
                      container: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                    }}
                    value={field.value || null}
                  />
                  {errors.status && (
                    <p className="mt-1 text-sm text-red-600">{errors.status.message}</p>
                  )}
                </div>
              )}
            />
          </div>
          <h2 className="text-2xl font-bold py-1 px-3 bg-white text-[#006F3E] rounded-md text-center shadow-sm">
            {order ? 'Edit Order' : 'New Order'}
          </h2>
          <div className="w-48"></div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-4">
              {renderField("date", "Date", "date", true)}
              {renderField("ship_date", "Ship Date", "date")}
              <div className="mb-2">
                <label htmlFor="ship_via" className="block text-sm font-medium text-gray-700 pb-1">
                  Ship Via:
                </label>
                <Controller
                  name="ship_via"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={field.value || null}
                      options={shipViaOptions}
                      isClearable
                      placeholder="Select shipping method"
                    />
                  )}
                />
              </div>
            </div>
            <div className="space-y-4">
              {renderField("so_number", "SO Number", "text", true)}
              {renderField("invoice_number", "Invoice Number")}
              {renderField("door_number", "Door Number", "text", true)}
            </div>
            <div className="space-y-4">
              <div className="flex-1">
                <label htmlFor="blueprint" className="block text-sm font-medium text-gray-700 mb-1">
                  Upload Blueprint (PDF)
                </label>
                <PDFUploader
                  orderId={order?.id}
                  currentBlueprint={currentPDF.blueprint}
                  currentBlueprintName={currentPDF.blueprint_filename}
                  uploadEndpoint="blueprint"
                  uploaderId="blueprint-uploader"
                  className="w-full"
                  variant="default"
                  selectedFile={selectedBlueprint}
                  onFileSelected={(file) => handleFileSelected(file, 'blueprint')}
                  onUploadComplete={(response) => {
                    setCurrentPDF({
                      blueprint: response.data.blueprint,
                      blueprint_filename: response.data.blueprint_filename
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <h3 className="text-lg font-medium text-[#006F3E] mb-2">Billing Address</h3>
              {renderAddressFields('billing')}
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium text-[#006F3E]">Shipping Address</h3>
                <button
                  type="button"
                  onClick={handleUseBillingAddress}
                  className="bg-[#006F3E] hover:bg-[#005a32] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                >
                  Use Billing Address
                </button>
              </div>
              {renderAddressFields('shipping')}
              <button
                type="button"
                onClick={() => handleMapSearch('shipping')}
                className="mt-2 inline-flex items-center px-3 py-2 border border-[#006F3E] text-sm leading-4 font-medium rounded-md text-white bg-[#006F3E] hover:bg-[#005a32] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#006F3E]"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                </svg>
                Search in Maps
              </button>
            </div>
            <div>
              <h3 className="text-lg font-medium text-[#006F3E] mb-2">Additional Info</h3>
              <div className="space-y-2">
                <Controller
                  name="phone"
                  control={control}
                  rules={{ validate: validatePhoneNumber }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone:</label>
                      <input
                        type="tel"
                        value={value}
                        onChange={(e) => onChange(formatPhoneNumber(e.target.value))}
                        className={`mt-1 block w-full py-2 px-3 border ${errors.phone ? 'border-red-500' : 'border-[#006F3E]'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm`}
                        placeholder="(XXX) XXX-XXXX"
                      />
                      {errors.phone && <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>}
                    </div>
                  )}
                />
                {renderField("eps_po_number", "EPS PO Number")}
                {renderField("po_delivery_date", "PO Delivery Date", "date")}
                <div className="flex space-x-2">
                  <div className="w-1/2">
                    {renderField("price", "Price", "number")}
                  </div>
                  <div className="w-1/2">
                    {renderField("freight_cost", "Freight Cost", "number")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-medium text-[#006F3E] mb-2">Special Delivery Information</h3>
            <Controller
              name="special_delivery_info"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  value={field.value || ''}
                  className="mt-1 block w-full py-2 px-3 border border-[#006F3E] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm"
                  rows="3"
                  placeholder="Enter any special delivery instructions here"
                ></textarea>
              )}
            />
          </div>

          <div>
            <h3 className="text-lg font-medium text-[#006F3E] mb-2">Building Info</h3>
            <div className="flex gap-4">
              <div className="flex-1">
                <Controller
                  name="building_info.description"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label htmlFor="building_info.description" className="block text-sm font-medium text-gray-700">
                        Building Description:
                      </label>
                      <Select
                        {...field}
                        options={buildingDescriptions}
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        placeholder="Select building description"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: '#006F3E',
                            '&:hover': {
                              borderColor: '#005a32',
                            },
                          }),
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          if (selectedOption?.value === 'custom') {
                            setValue('building_info.building_type', '');
                            setValue('building_info.is_custom', true);
                          } else {
                            setValue('building_info.building_type', selectedOption?.buildingType || '');
                            setValue('building_info.is_custom', false);
                          }
                          setValue('price', selectedOption?.buildingPrice || '');
                        }}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Door Placement Drawing
                </label>
                <PDFUploader
                  orderId={order?.id}
                  currentBlueprint={doorPlacementPDF.door_placement_drawing}
                  currentBlueprintName={doorPlacementPDF.door_placement_filename}
                  uploadEndpoint="door-placement-drawing"
                  uploaderId="door-placement-uploader"
                  variant="compact"
                  selectedFile={selectedDoorPlacement}
                  onFileSelected={(file) => handleFileSelected(file, 'door-placement-drawing')}
                  onUploadComplete={(response) => {
                    setDoorPlacementPDF({
                      door_placement_drawing: response.data.door_placement_drawing,
                      door_placement_filename: response.data.door_placement_filename
                    });
                  }}
                />
              </div>
            </div>

            {watch('building_info.description')?.value === 'custom' && (
              <div className="mt-4">
                <Controller
                  name="building_info.building_type"
                  control={control}
                  rules={{ required: "Building Type is required for custom descriptions" }}
                  render={({ field }) => (
                    <div>
                      <label htmlFor="building_info.building_type" className="block text-sm font-medium text-gray-700">Building Type:</label>
                      <Select
                        {...field}
                        options={BUILDING_TYPE_CHOICES}
                        className="basic-single"
                        classNamePrefix="select"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: errors.building_info?.building_type ? '#EF4444' : '#006F3E',
                            '&:hover': {
                              borderColor: errors.building_info?.building_type ? '#DC2626' : '#005a32',
                            },
                          }),
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          setValue('building_info.building_type', selectedOption);
                        }}
                      />
                      {errors.building_info?.building_type && (
                        <p className="mt-1 text-sm text-red-600">{errors.building_info.building_type.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>
            )}

            <div className="flex flex-col md:flex-row mt-4">
              <div className="w-full md:w-1/2 space-y-4 mr-4">
                {showSizeAndThickness && (
                  <>
                    {renderSelect('building_info.size', 'Size', settings.size)}
                    {renderSelect('building_info.thickness', 'Thickness', settings.thickness)}
                  </>
                )}
                {renderSelect('building_info.door_type', 'Door Type', settings.door_type)}
                {renderSelect('building_info.hinge_side', 'Hinge Side', HINGE_SIDE_OPTIONS)}
                {renderSelect('building_info.door_cladding', 'Door Cladding', settings.door_cladding)}
              </div>
              <div className="w-full md:w-1/2 space-y-4">
                <Controller
                  name="building_info.door_placement"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label htmlFor="door_placement" className="block text-sm font-medium text-gray-700">Door Placement:</label>
                      <textarea
                        {...field}
                        value={field.value || ''}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        rows="5"
                      ></textarea>
                    </div>
                  )}
                />
                {showSizeAndThickness && (
                  <>
                    {renderSelect('building_info.roof_color', 'Roof Color', settings.roof_color)}
                    {renderSelect('building_info.siding_color', 'Siding Color', settings.siding_color)}
                  </>
                )}
                {renderAdditionalItems()}
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-medium text-[#006F3E] mb-2">Notes</h3>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  value={field.value || ''}
                  className="mt-1 block w-full py-2 px-3 border border-[#006F3E] bg-white rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] sm:text-sm"
                  rows="3"
                  placeholder="Enter any notes here"
                ></textarea>
              )}
            />
          </div>

          <div className="flex justify-center space-x-3 mt-6">
            <button
              type="submit"
              disabled={isPrinting}
              className={`inline-flex items-center justify-center py-3 px-6 border border-transparent shadow-lg text-lg font-bold rounded-lg text-white bg-[#006F3E] hover:bg-[#005a32] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#005a32]`}
            >
              {order ? 'UPDATE' : 'SAVE'}
            </button>

            <button
              type="button"
              onClick={handlePrint}
              disabled={isPrinting}
              className={`inline-flex items-center justify-center py-3 px-6 border border-transparent shadow-lg text-lg font-bold rounded-lg text-black bg-[#A3D5A5] hover:bg-[#8BC48A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8BC48A]`}
            >
              {isPrinting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Printing...
                </>
              ) : (
                'PRINT'
              )}
            </button>

            <button
              type="button"
              onClick={handleClose}
              disabled={isPrinting}
              className={`inline-flex justify-center py-3 px-6 border border-transparent shadow-lg text-lg font-bold rounded-lg text-white bg-[#e57373] hover:bg-[#ef5350] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ef5350]`}
            >
              CLOSE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderForm;
